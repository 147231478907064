.order-info-container {
  background-color: #f5f5f5;
  padding: 24px;
}

.order-main-info,
.order-status,
.order-items,
.order-summary {
  background-color: white;
  padding: 24px;
  border-radius: 8px;
}

.order-item {
  display: grid;
  grid-template-columns: auto 1fr auto auto auto;
  gap: 16px;
  align-items: center;
  padding: 16px 0;
  border-bottom: 1px solid #f0f0f0;
}

.item-image {
  width: 80px;
  height: 80px;
  object-fit: cover;
}

.item-details {
  display: flex;
  flex-direction: column;
}

.item-name {
  font-size: 14px;
  margin-bottom: 4px;
}

.item-category {
  font-size: 12px;
  color: #8c8c8c;
}

.item-quantity {
  font-size: 14px;
  color: #262626;
}

.item-price {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.item-price-current {
  font-size: 14px;
  font-weight: bold;
  color: #262626;
}

.item-price-original {
  font-size: 12px;
  color: #8c8c8c;
  text-decoration: line-through;
}

.item-total-price {
  text-align: right;
}

.item-price-total {
  font-size: 16px;
  font-weight: bold;
  color: #9f60fc;
}

.ship-msg-list {
  list-style-type: none;
  padding-left: 0;
  position: relative;
}

.ship-msg-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
  position: relative;
}

.ship-msg-item-dot {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #36b37e;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  flex-shrink: 0;
  z-index: 1;
}

.ship-msg-item-dot .anticon {
  color: white;
  font-size: 14px;
}

.ship-msg-item-content {
  display: flex;
  flex-direction: column;
}

.ship-msg-item-datetime {
  color: #253858;
  font-weight: 500;
  margin-bottom: 4px;
}

.ship-msg-item-status {
  color: #253858;
}

.ship-msg-item:not(:last-child)::after {
  content: '';
  position: absolute;
  left: 11px;
  top: 24px;
  bottom: -16px;
  width: 2px;
  background-color: #36b37e;
}

.info-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.info-item .label {
  color: #8c8c8c;
}

.info-value-container {
  display: flex;
  align-items: center;
}

.info-value {
  text-align: right;
  margin-right: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px; /* Adjust this value as needed */
}

.copy-icon {
  cursor: pointer;
  color: #1890ff;
}

.copy-icon:hover {
  color: #40a9ff;
}

.info-icon {
  margin-right: 8px;
  color: #1890ff;
}

.order-main-info {
  background-color: white;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.address-info {
  margin-top: 16px;
}

.receiver-name {
  font-size: 16px;
  margin-bottom: 4px;
}

.receiver-phone {
  font-size: 14px;
  margin-bottom: 8px;
}

.receiver-address {
  display: flex;
  flex-direction: column;
}

.receiver-address Text {
  font-size: 14px;
  line-height: 1.5;
}

.address-detail {
  padding-left: 24px;
  color: #595959;
}

.order-summary {
  background-color: white;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.total-money {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 16px;
}

.total-money-label {
  font-size: 16px;
  color: #262626;
}

.total-money-value {
  font-size: 24px;
  font-weight: bold;
  color: #9f60fc;
}

.order-status {
  background-color: white;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  margin-bottom: 24px;
}

.current-status {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  padding: 16px;
  background-color: #f6ffed;
  border-radius: 8px;
  border: 1px solid #b7eb8f;
}

.current-status .anticon {
  font-size: 24px;
  margin-right: 16px;
  color: #52c41a;
}

.status-text {
  font-size: 18px;
  color: #52c41a;
}

.ant-timeline {
  margin-top: 16px;
}

.ant-timeline-item-tail {
  border-left: 2px solid #e8e8e8;
}

.ant-timeline-item-head {
  background-color: transparent;
  width: 20px;
  height: 20px;
}

.ant-timeline-item-content {
  padding-bottom: 20px;
  padding-left: 12px;
}

.applied-vouchers {
  background-color: white;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  margin-bottom: 24px;
}

.voucher-card {
  margin-top: 16px;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  overflow: hidden;
}

.voucher-card .ant-card-body {
  padding: 12px;
}

.voucher-content {
  display: flex;
  align-items: flex-start;
}

.voucher-details {
  margin-left: 12px;
  display: flex;
  flex-direction: column;
}

.voucher-card .ant-avatar {
  background-color: #f0f0f0;
  border-radius: 8px;
}

.voucher-card .ant-typography {
  line-height: 1.4;
}

.voucher-card .ant-typography-secondary {
  font-size: 12px;
  color: #8c8c8c;
}
.order-info-container {
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.order-main-info, .order-status, .order-items, .order-summary {
  background: #fff;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 24px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.current-status {
  display: flex;
  align-items: center;
  gap: 10px;
  background: #e6f7ff;
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 16px;
}

.order-items .order-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  border-bottom: 1px solid #f0f0f0;
}

.order-items .order-item:last-child {
  border-bottom: none;
}

.item-name {
  font-size: 16px;
}

.voucher-card {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 12px;
  border: 1px solid #d9d9d9;
  background: #f6ffed;
}
.ship-history-header {
  display: flex;
}

.collapse-button {
  padding: 4px 8px;
  height: 32px;
}

.collapse-button:hover {
  background: #f5f5f5;
}