/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-pro-table-search {
  margin-bottom: 16px;
  padding: 24px;
  padding-bottom: 0;
  background: #fff;
  border-radius: 2px;
}
.ant-pro-table-search::before {
  display: table;
  content: '';
}
.ant-pro-table-search::after {
  display: table;
  clear: both;
  content: '';
}
.ant-pro-table-search.ant-pro-table-form {
  margin: 0;
  padding: 0 16px;
  overflow: unset;
}
.ant-pro-table-search .ant-form-item-label {
  min-width: 40px;
  text-align: right;
}
.ant-pro-table-search .ant-row:not(.ant-form-item) > .ant-col {
  padding: 4px 0;
}
.ant-pro-table-search .ant-form-item {
  display: flex;
}
.ant-pro-table-search .ant-form-item-control {
  flex: 1;
}
.ant-pro-table-search-option .ant-form-item-control-input {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
}
.ant-pro-table-search-form-option .ant-form-item {
  margin: 0;
}
.ant-pro-table-search-form-option .ant-form-item-label {
  opacity: 0;
}
.ant-pro-table-search-form-option .ant-form-item-control-input {
  justify-content: flex-start;
}
@media (max-width: 575px) {
  .ant-pro-table-search .ant-form-item {
    display: inline;
  }
  .ant-pro-table-search .ant-legacy-form-item {
    display: inline;
  }
  .ant-pro-table-search .ant-form-item-label {
    min-width: 80px;
    text-align: left;
  }
  .ant-pro-table-search .ant-legacy-form-item-label {
    min-width: 80px;
    text-align: left;
  }
}
