.section-title {
  font-size: 16px;
  color: #7a34f0;
}

.info-item {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}

.info-value {
  display: block;
  font-weight: 600;
  font-size: 14px;
}

.info-icon {
  margin-right: 8px;
  color: #7a34f0;
}

.label {
  margin-right: 8px;
  font-weight: 800 !important;
  color: #000 !important;
  font-size: 14px;
  width: 300px;
}

.link-tracking {
  text-decoration: underline !important;
}

.section-title-customer {
  font-size: 16px;
  font-weight: 600;
  margin-left: 8px;
  color: #7a34f0;
}

.total-price {
  margin-top: 10px;
  display: flex;
  justify-content: end;
  width: 100%;
}

.total-price .section-title-customer {
  margin-left: 0px;
}

.total-price .label {
  width: auto !important;
}

.line-out {
  border-right: 1px solid #7a34f0;
  height: 100%;
  display: inline-block;
  padding-right: 5px;
  margin-bottom: 50px;
}

.ship-msg-list {
  list-style-type: none;
  padding-left: 0;
  position: relative;
}

.line-in {
  width: 2px;
  height: calc(100% - 20px);
  background-color: #36b37e;
  display: block;
  position: absolute;
  left: 5px;
  top: 10px;
}

.ship-msg-item {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.ship-msg-item-dot {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #36b37e;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  flex-shrink: 0;
}

.ship-msg-item-dot .anticon {
  color: white;
  font-size: 14px;
}

.ship-msg-item-content {
  display: flex;
  align-items: center;
}

.ship-msg-item-datetime {
  color: #253858;
  font-weight: 500;
  margin-right: 12px;
}

.ship-msg-item-status {
  color: #253858;
}

.ship-msg-item:not(:last-child)::after {
  content: '';
  position: absolute;
  left: 11px;
  top: 24px;
  bottom: -12px;
  width: 2px;
  background-color: #36b37e;
}
