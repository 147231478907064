.flashSaleProduct {
  padding: 20px;
  max-width: auto;
  margin: 0 0;
}

.flashSaleProduct :global(.ant-form-item) {
  margin-bottom: 20px;
}

.flashSaleProduct :global(.ant-switch) {
  margin-left: 10px;
}

.flashSaleProduct :global(.ant-input),
.flashSaleProduct :global(.ant-select),
.flashSaleProduct :global(.ant-picker) {
  width: 100%;
}

.customTable {
  margin-top: 30px;
}

.customTable :global(.ant-table-thead > tr > th) {
  background-color: #f0f2f5;
}

.productImage {
  width: 50px;
  height: 50px;
  object-fit: cover;
  display: block;
  margin: 0 auto;
}

.actionButtons {
  display: flex;
  gap: 10px;
}

.editButton,
.deleteButton {
  padding: 4px 8px;
}

.editButton {
  background-color: #1890ff;
  color: white;
}

.deleteButton {
  background-color: #ff4d4f;
  color: white;
}

.saveButton {
  margin-top: 20px;
  float: right;
}
