/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-pro-table-index-column {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
}
.ant-pro-table-index-column-border {
  color: #fff;
  font-size: 12px;
  line-height: 12px;
  background-color: #314659;
  border-radius: 9px;
}
.ant-pro-table-index-column-border.top-three {
  background-color: #979797;
}
