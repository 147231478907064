.account-container {
  padding: 24px;
}

.account-card {
  max-width: 800px;
  margin: 0 auto;
}

.create-account-form {
  max-width: 500px;
  margin: 0 auto;
}

.create-account-form .ant-typography {
  text-align: center;
  margin-bottom: 24px;
}

.logo-container {
  margin-bottom: 20px;
  text-align: center;
}

.store-logo {
  max-width: 200px;
  max-height: 200px;
  object-fit: contain;
}

.info-item {
  margin-bottom: 16px;
}

.info-item .ant-typography-strong {
  margin-right: 8px;
}

.reset-password-btn {
  margin-top: 16px;
}

.submit-btn {
  margin-top: 16px;
}

.form {
  margin-top: 24px;
}

.account-info {
  padding: 20px;
}

.captcha-container {
  margin: 20px 0;
  max-width: 300px;
}
