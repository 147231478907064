/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-pro-table-alert {
  margin-bottom: 16px;
}
.ant-pro-table-alert-info {
  display: flex;
  align-items: center;
  transition: 0.3 all;
}
.ant-pro-table-alert-info-total {
  flex: 1;
}
.ant-pro-table-alert-info-option {
  min-width: 48px;
  padding-left: 16px;
}
