/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-pro-table {
  z-index: 1;
}
.ant-pro-table .wrapper1 {
  width: 100%;
  overflow: auto;
  height: 12px;
  visibility: hidden;
}
.ant-pro-table:not(:root):fullscreen {
  min-height: 100vh;
  overflow: auto;
  background: #fff;
}
.ant-pro-table .ant-pagination {
  padding: 0 24px;
}
.ant-pro-table .ant-table-row .ant-table-cell:first-child {
  padding-left: 24px;
}
.ant-pro-table .ant-table-row .ant-table-cell:first-child a {
  padding: 0;
}
.ant-pro-table .ant-table-content tr:first-child > th:first-child {
  padding-left: 24px;
}
.ant-pro-table .ant-table-content tr:first-child > th:first-child a {
  padding: 0;
}
.ant-pro-table td.ant-table-cell > a {
  font-size: 14px;
}
.ant-pro-table-toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  padding: 0 24px;
}
.ant-pro-table-toolbar .tech-listtoolbar-container {
  padding-right: 0;
  padding-left: 0;
}
.ant-pro-table-toolbar-option {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.ant-pro-table-toolbar-item-icon {
  margin-left: 16px;
  font-size: 16px;
  cursor: pointer;
}
.ant-pro-table-toolbar-item-icon:first-child {
  margin-left: 8px;
}
.ant-pro-table-toolbar-title {
  flex: 1;
  color: #535353;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  opacity: 0.85;
}
@media (max-width: 575px) {
  .ant-pro-table-toolbar {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 16px;
    margin-left: 16px;
    padding: 0;
    line-height: normal;
  }
  .ant-pro-table-toolbar-option {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .ant-pro-table-toolbar-default-option {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
  }
}
